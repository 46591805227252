import { EventEmitter, Injectable } from '@angular/core';
import { DateFilter, FleetAndDateFilter } from 'src/app/customer/Models/dateFilter';
import { FleetFilter } from 'src/app/customer/Models/fleetFilter';

@Injectable({
  providedIn: 'root'
})
export class FleetFilterService {

  private filter: FleetFilter;
  private filterInit: boolean;

  private dateFilter: DateFilter;
  private dateFilterInit: boolean;

  filterSet: EventEmitter<FleetFilter>;
  dateFilterSet: EventEmitter<DateFilter>;
  filterAndDateSet: EventEmitter<FleetAndDateFilter>;

  constructor() {
    this.filterSet = new EventEmitter<FleetFilter>();
    this.dateFilterSet = new EventEmitter<DateFilter>();
    this.filterAndDateSet = new EventEmitter<FleetAndDateFilter>();
  }

  setFilter(filter?: FleetFilter) {
    this.filter = filter;
    this.filterInit = true;
    this.filterSet.emit(filter);
    if (this.filterInit && this.dateFilterInit) {
      this.emitFilterAndDate();
    }
  }

  setDateFilter(dateFilter: DateFilter) {
    this.dateFilter = dateFilter;
    this.dateFilterInit = true;
    this.dateFilterSet.emit(dateFilter);

    if (this.filterInit && this.dateFilterInit) {
      this.emitFilterAndDate();
    }
  }

  disposeFilter() {
    this.dateFilterInit = false;
    this.filterInit = false;
  }

  /**
   * Used to retrieve a single instance of the fleet filter on demand.
   * @returns Stored Fleet Filter
   */
  getStoredFilter(): FleetFilter {
    const savedFilterString = localStorage.getItem('fleet-filter');
    let filter: FleetFilter;
    if (savedFilterString) {
      filter = JSON.parse(savedFilterString);
    }
    return filter;
  }

  getFilterInitialisation() {
    return {
      filterInit: this.filterInit,
      dateFilterInit: this.dateFilterInit
    }
  }

  getCurrentDateAndFleetFilter(): FleetAndDateFilter {
    if (!this.filterInit || !this.dateFilterInit) {
      throw new Error('Fleet filter not fully initialised at this time.');
    }
    return {
      filter: this.filter,
      dateFilter: this.dateFilter
    }
  }

  //this is a horrible hack to get the speed dash working 'fast'
  forceEmit() {
    this.filterSet.emit(this.filter);
    this.dateFilterSet.emit(this.dateFilter);
    this.filterAndDateSet.emit({
        filter: this.filter,
        dateFilter: this.dateFilter
    })
  }

  private emitFilterAndDate() {
    this.filterAndDateSet.emit({
      filter: this.filter,
      dateFilter: this.dateFilter
    })
  }

}
